const testimonialMarkup = data => {
  const {
    title,
    content,
    position,
    image_url,
    alt_text
  } = data;
  let markup = `
        <div class="testimonial-card flex-layout align-items-center">
          <div class="testimonial-card__image">
            <img src="${image_url}" alt="${alt_text}" />
          </div>
          <div class="testimonial-card__text">
            ${content}
            <h3>${title}</h3>
            <h4>${position}</h4>
          </div>
        </div>
  `;
  return markup;
};
const testimonials_container = document.getElementById("testimonials-container");
const loading_container = document.getElementsByClassName("testimonials__loading");
const handlePaginationClick = currentPage => {
  const pagination_links = document.querySelectorAll(".page-numbers a");
  for (let p = 0; p < pagination_links.length; p++) {
    pagination_links[p].addEventListener("click", event => {
      event.preventDefault();
      if (pagination_links[p].classList.contains("prev")) {
        loadTestimonials(currentPage - 1);
      } else if (pagination_links[p].classList.contains("next")) {
        loadTestimonials(currentPage + 1);
      } else {
        const this_number = parseInt(pagination_links[p].innerHTML);
        loadTestimonials(this_number);
      }
    });
  }
};
function loadTestimonials(page) {
  jQuery.ajax({
    url: oval_vars.ajaxurl,
    type: "post",
    data: {
      action: "oval_ajax_getTestimonials_",
      page: page
    },
    //dataType: "json",
    beforeSend: function () {
      //testimonials_container.innerHTML = "";
      loading_container[0].style.display = "flex";
    },
    success: function (data) {
      console.log("data:", data);
      if (data && data.length > 0) {
        testimonials_container.innerHTML = data;
        handlePaginationClick(page);
      } else {
        testimonials_container.innerHTML = `<p class="text-center" style="width: 100%">No testimonails to show</p>`;
      }
      loading_container[0].style.display = "none";
    },
    error: function (response) {
      console.log(response);
      loading_container[0].innerHTML = `<p class="text-center" style="width: 100%">There was an error loading the posts</p>`;
    }
  });
}
if (testimonials_container) {
  loadTestimonials(1);
}
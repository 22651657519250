(function ($) {
  //loadPosts();

  $(".testimonials-carousel__slider").slick({
    fade: true,
    responsive: [{
      breakpoint: 640,
      settings: {
        arrows: false,
        dots: true
      }
    }]
  });
  $(".logo-carousel__slider").slick({
    slidesToShow: 3,
    responsive: [{
      breakpoint: 640,
      settings: {
        slidesToShow: 2,
        arrows: false,
        dots: true
      }
    }]
  });
  $(".video-carousel").slick({
    responsive: [{
      breakpoint: 640,
      settings: {
        arrows: false,
        dots: true
      }
    }]
  });
  $(".video-carousel").on("beforeChange", function (event, slick, currentSlide, nextSlide) {
    $(".video-carousel video").each(function () {
      this.pause();
      $(this).siblings(".video-slide__play").show();
    });
  });

  // $(".fancybox").fancybox({
  //     padding: 0,
  // });

  setTimeout(function () {
    document.querySelector("body").classList.add("loaded");
  }, 800);
  scrollDown();
  window.addEventListener("scroll", function (e) {
    scrollDown();
  });
  setTimeout(function () {
    if ($(".rellax").size() > 0) {
      var rellax = new Rellax(".rellax");
    }
  }, 100);
  $("input.wpcf7-phonetext").keypress(function (e) {
    var key_codes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 0, 8];
    if (!($.inArray(e.which, key_codes) >= 0)) {
      e.preventDefault();
    }
  });
})(jQuery);

// window.onbeforeunload = function () {
//   let hash = window.location.hash;
//   console.log(hash);
//   if (hash != "") {
//     window.scrollTo(0, 0);
//   }
// };

// document.addEventListener("DOMContentLoaded", (event) => {
//   let hash = window.location.hash;
//   console.log(hash);
//   if (hash != "") {
//     setTimeout(() => {
//       window.scrollTo(0, 100);
//     }, 1);
//     setTimeout(() => {
//       const selector = document.querySelector(hash);
//       var topPos = selector.offsetTop;
//       jQuery("html,body").animate({ scrollTop: topPos - 100 }, 1000);
//       selector.querySelector(".service-card__button").click();
//     }, 600);
//   }
// });

const share_links = document.querySelectorAll(".share-links a");
if (share_links) {
  for (let s = 0; s < share_links.length; s++) {
    share_links[s].addEventListener("click", event => {
      if (share_links[s].classList.contains("popup-share")) {
        event.preventDefault();
        const thisHref = share_links[s].href;
        window.open(thisHref, "newwindow", "width=460, height=500, top=100, left=100");
      }
    });
  }
}
const open_menu = document.getElementById("open-menu");
if (open_menu) {
  open_menu.addEventListener("click", () => {
    document.querySelector(".header").classList.add("mobile-open");
  });
}
const close_menu = document.getElementById("close-menu");
if (close_menu) {
  close_menu.addEventListener("click", () => {
    document.querySelector(".header").classList.remove("mobile-open");
  });
}
const menu_item_with_child = document.querySelectorAll(".header__nav li.has-children");
if (menu_item_with_child) {
  for (let m = 0; m < menu_item_with_child.length; m++) {
    menu_item_with_child[m].addEventListener("mouseover", () => {
      const top = window.scrollY;
      if (top <= 80) {
        document.getElementById("header").classList.add("scroll");
        headerLogo.setAttribute("src", logoData);
      }
    });
    menu_item_with_child[m].addEventListener("mouseleave", () => {
      const top = window.scrollY;
      if (top <= 80) {
        document.getElementById("header").classList.remove("scroll");
        headerLogo.setAttribute("src", logoSrc);
      }
    });
    menu_item_with_child[m].querySelector(".open-subnav").addEventListener("click", () => {
      for (let x = 0; x < menu_item_with_child.length; x++) {
        if (x !== m) {
          menu_item_with_child[x].classList.remove("subnav-open");
        }
      }
      if (menu_item_with_child[m].classList.contains("subnav-open")) {
        menu_item_with_child[m].classList.remove("subnav-open");
      } else {
        menu_item_with_child[m].classList.add("subnav-open");
      }
    });
  }
}
const site_selector = document.querySelector(".site-selector");
if (site_selector) {
  site_selector.querySelector(".site-selector__current").addEventListener("click", () => {
    site_selector.classList.toggle("open");
  });
}

// const controller = new ScrollMagic.Controller();
// var tween = TweenMax.to(".overlapping-card-0", 0.5, {
//   scale: 1,
//   ease: Linear.easeNone,
// });
// const sceneOne = new ScrollMagic.Scene({
//   triggerElement: ".overlapping-cards__inner",
//   duration: 1500,
//   offset: 250,
// })
//   .setTween(tween)
//   .setPin(".overlapping-card-0")
//   .addTo(controller);

// var tween2 = TweenMax.to(".overlapping-card-1", 1, {
//   scale: 1,
//   ease: Linear.easeNone,
// });
// const scene2 = new ScrollMagic.Scene({
//   triggerElement: ".overlapping-cards__inner",
//   duration: 1500,
//   offset: 250,
// })
//   .setTween(tween2)
//   .setPin(".overlapping-card-1")
//   .addTo(controller);

// const overlappingCards = document.querySelector(".overlapping-cards__inner");
// if (overlappingCards) {
//   const win_width = window.innerWidth;
//   if (win_width > 640) {
//     let tl = gsap.timeline({
//       scrollTrigger: {
//         trigger: ".overlapping-cards__inner",
//         pin: true,
//         pinSpacing: true,
//         start: "-400px top", // when the top of the trigger hits the top of the viewport
//         end: "+=400", // end after scrolling 1000px beyond the start
//         scrub: 2, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
//       },
//     });

//     tl.from(".overlapping-card-0", {
//       yPercent: 100,
//       opacity: 0,
//     });
//     tl.from(".overlapping-card-1", {
//       yPercent: 100,
//       opacity: 0,
//     });
//     tl.from(".overlapping-card-2", {
//       yPercent: 100,
//       opacity: 0,
//     });
//     tl.from(".overlapping-card-3", {
//       yPercent: 100,
//       opacity: 0,
//     });
//     tl.from(".overlapping-card-4", {
//       yPercent: 100,
//       opacity: 0,
//     });
//   }
// }

const accordItems = document.getElementsByClassName("accordion-item");
if (accordItems) {
  for (let i = 0; i < accordItems.length; i++) {
    const cardButton = accordItems[i].querySelector(".accordion-item__button");
    cardButton.addEventListener("click", () => {
      if (accordItems[i].classList.contains("open")) {
        //accordItems[i].classList.remove("open");
      } else {
        for (let s = 0; s < accordItems.length; s++) {
          accordItems[s].classList.remove("open");
        }
        accordItems[i].classList.add("open");
      }
    });
  }
}
const video_slide = document.querySelectorAll(".video-slide");
if (video_slide) {
  for (let v = 0; v < video_slide.length; v++) {
    const play_button = video_slide[v].querySelector(".video-slide__play");
    const video = video_slide[v].querySelector("video");
    play_button.addEventListener("click", () => {
      video.play();
      play_button.style.display = "none";
    });
  }
}
document.addEventListener("wpcf7mailsent", function (event) {
  document.getElementById("contact-form-content").style.display = "none";
  document.getElementById("contact-form-response").style.display = "block";
}, false);
const logo_filters = document.querySelectorAll(".logo-filter__buttons li");
if (logo_filters) {
  for (let l = 0; l < logo_filters.length; l++) {
    logo_filters[l].addEventListener("click", () => {
      const filter_cat = logo_filters[l].dataset.cat;
      const logo_items = document.querySelectorAll(".logo-card");
      for (let k = 0; k < logo_filters.length; k++) {
        logo_filters[k].classList.remove("active");
      }
      logo_filters[l].classList.add("active");
      for (let i = 0; i < logo_items.length; i++) {
        if (filter_cat === "all") {
          logo_items[i].style.display = "flex";
        } else {
          if (logo_items[i].classList.contains(filter_cat)) {
            logo_items[i].style.display = "flex";
          } else {
            logo_items[i].style.display = "none";
          }
        }
      }
    });
  }
}
const actualBtn = document.getElementById("add-cv");
const fileChosen = document.querySelector(".file-name");
if (actualBtn) {
  actualBtn.addEventListener("change", function () {
    fileChosen.textContent = this.files[0].name;
  });
}
const postMarkup = data => {
  const {
    title,
    date,
    image_url,
    alt_text,
    permalink
  } = data;
  let markup = `
        <a class="post-card" href="${permalink}">
          <figure class="post-card__image">
            <img src="${image_url}" alt="${alt_text}" />
          </figure>
          <div class="post-card__text">
            <h3>${title}</h3>
            <h4>${date}</h4>
          </div>
        </a>
  `;
  return markup;
};
const posts_container = document.getElementById("posts-container");
const posts_loading = document.getElementsByClassName("blog-grid__loading")[0];
const handlePostsPaginationClick = currentPage => {
  const pagination_links = document.querySelectorAll(".page-numbers a");
  for (let p = 0; p < pagination_links.length; p++) {
    pagination_links[p].addEventListener("click", event => {
      event.preventDefault();
      console.log("hola");
      if (pagination_links[p].classList.contains("prev")) {
        loadPosts(currentPage - 1);
      } else if (pagination_links[p].classList.contains("next")) {
        loadPosts(currentPage + 1);
      } else {
        const this_number = parseInt(pagination_links[p].innerHTML);
        loadPosts(this_number);
      }
    });
  }
};
function loadPosts(page) {
  jQuery.ajax({
    url: oval_vars.ajaxurl,
    type: "post",
    data: {
      action: "oval_ajax_getPosts_",
      page: page
    },
    //dataType: "json",
    beforeSend: function () {
      //posts_container.innerHTML = "";
      posts_loading.style.display = "flex";
    },
    success: function (data) {
      console.log("data:", data);
      if (data && data.length > 0) {
        posts_container.innerHTML = data;
        handlePostsPaginationClick(page);
      } else {
        posts_container.innerHTML = `<p class="text-center" style="width: 100%">No posts to show</p>`;
      }
      posts_loading.style.display = "none";
    },
    error: function () {
      posts_loading.innerHTML = `<p class="text-center" style="width: 100%">There was an error loading the posts</p>`;
    }
  });
}
if (posts_container) {
  loadPosts(1);
}